(function () {
  "use strict";
  const app = {
    init() {
      svg4everybody();
  
      this.slHero();
  
      this.toggler();
  
      const clickout = new clickOut({
        className: '.js-clickout', // class name where outside click
        activeClass: 'is-active', // class name for delete
        after: elems => {
          this.blockScroll(false);
        } // after click
      });
    },
  
    toggler() {
      let toggle = new Unitoggle({
        onOpen: tab => {
          this.blockScroll(true);
        },
        onClose: tab => {
          this.blockScroll(false);
        } // After close callback
      });
    },
  
    blockScroll(status = false) {
      if (!status) {
        document.documentElement.classList.remove('is-no-scroll');
        return;
      }
  
      document.documentElement.classList.add('is-no-scroll');
    },
  
    slHero() {
      const el = document.querySelector('#sl-hero');
  
      if (!el) return;
  
      const sl = el.querySelector('.js-sl');
  
      const btn_prev = el.querySelector('.js-prev');
      const btn_next = el.querySelector('.js-next');
  
      new Swiper(sl, {
        slidesPerView: 'auto',
        normalizeSlideIndex: true,
        loop: true,
        loopedSlides: 2,
        centeredSlides: true,
        observeSlideChildren: true,
        navigation: {
          prevEl: btn_prev,
          nextEl: btn_next,
          disabledClass: 'is-desabled'
        },
        autoplay: {
          delay: 10000
        },
        on: {
          slideChangeTransitionEnd: sw => {
            const currentIdx = sw.activeIndex;
            const slide = sw.slides[currentIdx];
  
            sw.slides.forEach(item => item.classList.remove('is-active'));
  
            slide.classList.add('is-active');
          }
        }
      });
    }
  };
  

  document.addEventListener('DOMContentLoaded', () => {
    app.init();
  });
}());
